import { Options, Vue } from "vue-class-component";
import Modal from "@/components/modal/modal.vue";
import DropdownSearch from "@/modules/dropdown-search/dropdown-search.vue";
import LevelItem from "./entity/LevelItem";
import LevelPageItems from "./entity/LevelPageItems";
import LevelRequestItem from "./entity/LevelRequestItem";
import { useToast } from "vue-toastification";

@Options({
    name: "app-select-level",
    components: {
        "app-modal": Modal,
        "app-dropdown-search": DropdownSearch,
    },
    props: {
        levelApi: {
            type: Object,
            default: {},
        },
        levelType: {
            type: String,
            default: "show", //show|select|noDrill|onLest
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        selectFunction: Function,
        searchFieldList: {
            type: Object,
            default: {},
        },
        selectItem: {
            type: Object,
            default: {},
        },
        maxParentId: {
            type: Number,
            default: -1,
        },
        defaultId: {
            type: Number,
            default: -1,
        },
    },
})
export default class SelectLevel extends Vue {
    private isOpen = false;
    private levelSelectModalElement: any;
    private levelApi: any;
    private multiple = false;
    protected isLoading = false;
    protected toast = useToast();
    private maxParentId = -1;
    private defaultId = -1;
    private selectItem: any = {};
    private selectFunction: any;
    // private value: any = null;
    // private title: any = null;
    private cType: [string, number] = null;
    private selected: any = {};

    public onValueChange(event: any, item: any) {
        if (event.target.nodeName !== "SPAN") {
            if (this.selected.hasOwnProperty(item.id)) {
                if (!this.multiple) this.selected = {};
                delete this.selected[item.id];
            } else {
                if (!this.multiple) this.selected = {};
                this.selected[item.id] = { parent: this.levelRequestItem.parentId, title: item.name, value: item.id };
            }
            this.selected = { ...this.selected };
        }
    }

    public isSelected(id: any) {
        return this.selected.hasOwnProperty(id);
    }

    public async submitFunction(): Promise<any> {
        const selectedArray = Object.values(this.selected);
        if (!this.multiple) {
            //单选
            if (this.$tools.isEmpty(selectedArray)) {
                return await this.selectFunction({});
            } else {
                return await this.selectFunction(selectedArray[0]);
            }
        } else {
            //多选
            return await this.selectFunction(selectedArray);
        }
    }

    public async mounted(): Promise<void> {
        this.levelSelectModalElement = this.$refs.levelSelectModal;
    }

    //搜索功能开始
    protected levelRequestItem: LevelRequestItem = new LevelRequestItem();
    protected searchCancel() {
        this.levelRequestItem.search = false;
        this.levelRequestItem.searchField = "all";
        this.levelRequestItem.searchKey = "";
        this.getList(1, this.levelRequestItem.parentId);
    }
    protected searchFunction(filed: string) {
        if (this.$tools.isEmpty(this.levelRequestItem.searchKey)) {
            this.searchCancel();
        } else {
            this.levelRequestItem.search = true;
            this.levelRequestItem.searchField = filed;
            this.getList(1, this.levelRequestItem.parentId);
        }
    }
    //搜索功能结束

    //列表功能开始
    protected levelPageItems: LevelPageItems = new LevelPageItems();
    protected levelItems: Array<LevelItem> = [];
    protected getList(page: number, parentId: number): void {
        if (parentId < -1) {
            this.toast.error("上级ID不能小余-1");
            return;
        }
        //支持默认值
        if (this.isOpen && this.defaultId > 0) {
            this.levelRequestItem.defaultId = this.defaultId;
            this.isOpen = false;
        } else {
            this.levelRequestItem.defaultId = -1;
        }
        this.levelRequestItem.cType = this.cType;
        this.levelRequestItem.parentId = parentId;
        if (page > 0) this.levelRequestItem.page = page;
        this.isLoading = true;
        this.levelApi
            .levelSelect(this.levelRequestItem)
            .then((res: any) => {
                this.levelPageItems.total = res.data.total;
                this.levelPageItems.pages = res.data.pages;
                this.levelPageItems.current = res.data.current;
                this.levelPageItems.size = res.data.size;
                this.levelPageItems.pid = res.data.pid;
                this.levelPageItems.pName = res.data.pName;
                this.levelItems = res.data.records;

                //为支持默认ID，需要回写新的上级ID
                if (res.data.parentId) this.levelRequestItem.parentId = res.data.parentId;
                //console.log(this.levelItems);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
    //列表功能结束

    //下钻，清除搜索
    public onDown(id: number) {
        this.levelRequestItem.search = false;
        this.levelRequestItem.searchField = "all";
        this.levelRequestItem.searchKey = "";
        this.getList(1, id);
    }

    public openModal() {
        if (this.selectItem.parent < -1) {
            this.toast.error("上级ID不能小余-1");
            return;
        }
        this.isOpen = true;
        this.cType = this.selectItem.cType || "";
        this.selected = {};
        if (!this.multiple) {
            //单选
            if (!this.$tools.isEmpty(this.selectItem.value))
                this.selected[this.selectItem.value] = {
                    parent: this.selectItem.parent,
                    title: this.selectItem.title,
                    value: this.selectItem.value,
                };
        } else {
            //多选
            for (const key in this.selectItem.value) {
                if (!this.$tools.isEmpty(this.selectItem.value[key].value)) {
                    this.selected[this.selectItem.value[key].value] = {
                        parent: this.selectItem.value[key].parent,
                        title: this.selectItem.value[key].title,
                        value: this.selectItem.value[key].value,
                    };
                }
            }
        }
        this.getList(0, this.selectItem.parent);
        this.$nextTick(() => {
            this.levelSelectModalElement.openModal();
        }).then();
    }

    public closeModal() {
        this.levelSelectModalElement.closeModal();
    }

    public toggleModal() {
        this.levelSelectModalElement.toggleModal();
    }

    get disBackBtn() {
        return this.levelRequestItem.parentId === this.maxParentId;
    }
    get disUpBtn() {
        if (this.levelPageItems.total <= 0) return true;
        return this.levelPageItems.current <= 1;
    }
    get disDownBtn() {
        if (this.levelPageItems.total <= 0) return true;
        return this.levelPageItems.current >= this.levelPageItems.pages;
    }
    private btnBackPage() {
        if (!this.disBackBtn) {
            this.levelRequestItem.search = false;
            this.levelRequestItem.searchField = "all";
            this.levelRequestItem.searchKey = "";
            this.getList(1, this.levelPageItems.pid);
        }
    }
    private btnUpPage() {
        if (!this.disUpBtn) {
            let page = this.levelPageItems.current - 1;
            if (page < 1) page = 1;
            this.getList(page, this.levelRequestItem.parentId);
        }
    }
    private btnDownPage() {
        if (!this.disDownBtn) {
            let page = this.levelPageItems.current + 1;
            if (page > this.levelPageItems.pages) page = this.levelPageItems.pages;
            this.getList(page, this.levelRequestItem.parentId);
        }
    }
}
